<div class="row-sm-2 custom-search-bar-height">
  <mat-toolbar class="search-block" *ngIf="!isCollapsed">
    <button class="search-icon" mat-icon-button disabled>
      <mat-icon>search</mat-icon>
    </button>
    <input
      class="search-control regular-font-search-text"
      type="text"
      placeholder="Search"
      [(ngModel)]="searchText"
      #searchbar
    />
    <button
      *ngIf="searchText.length"
      mat-button
      mat-icon-button
      (click)="searchClose()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<div class="row-sm-10 custom-scroll" *ngIf="!isCollapsed">
  <mat-accordion multi="true">
    <mat-expansion-panel
      *ngFor="let category of availableCategories"
      (opened)="showNodesCategoryWise(category)"
    >
      <mat-expansion-panel-header class="sidenav-color">
        <mat-panel-title>
          {{ category | uppercase }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="nav-content">
        <ng-container
          *ngFor="let node of categorisedNodes[category] | filter: searchText"
        >
          <div class="nav-svg">
            <svg viewBox="0 0 100 100">
              <svg:g [node]="node" [hostComponent]="'control_panele'"></svg:g>
            </svg>

            <div class="regular-font-svg-label-text">
              {{ node.label | split: "- " | slice: 0:7 }}...
            </div>
          </div>
        </ng-container>
      </div>
      <div
        class="no-component"
        *ngIf="(categorisedNodes[category] | filter: searchText).length === 0"
      >
        No component found
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
