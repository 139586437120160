import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiagComponent } from './diag.component';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { NodeModule } from '../node/node.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SidebarModule } from '../sidebar/sidebar.module';

@NgModule({
  declarations: [DiagComponent],
  imports: [
    CommonModule,
    NgxGraphModule,
    NodeModule,
    MatDialogModule,
    MatIconModule,
    SidebarModule,
  ],
  exports: [DiagComponent],
})
export class DiagModule {}
