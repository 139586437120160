<div>
  <div mat-dialog-content class="matDialogContent">
    <p class="">
      Are you sure to leave this page? Changes you made will not be saved.
      <!-- {{ "common.project_updated" | translate }}
      <mat-icon class="iconColor" aria-hidden="false">task_alt </mat-icon> -->
    </p>
  </div>
  <div mat-dialog-actions class="matDialogContent">
    <button class="btn-add dashboardMargin" (click)="gotoDashboard()">
      Back to Project Configuration
    </button>
    <button
      class="btn-cancel"
      [mat-dialog-close]="true"
      cdkFocusInitial
      (click)="reload()"
    >
      {{ "common.button.continue_editing" | translate }}
    </button>
  </div>
</div>
