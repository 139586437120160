import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AdHostDirective } from 'src/app/directives/ad-host.directive';
import { Inode } from 'src/app/interfaces/node.model';
import { ComponentDetailsComponent } from '../component-details/component-details.component';
import { MatDialog } from '@angular/material/dialog';
import { ComponentFlavoursComponent } from '../component-flavours/component-flavours.component';
@Component({
  selector: 'g[node]',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss'],
})
export class NodeComponent implements OnInit {
  @Input() hostComponent: any;
  @Input() node: any;

  isHovered = false;
  public size = 70;
  public SVGscale = 10;
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}
  Selectcomponent(node: Inode): void {
    if (this.hostComponent === 'canvas') {
      const dialogRef = this.dialog.open(ComponentDetailsComponent, {
        width: '450px',
        data: { node },
      });
    }
    if (this.hostComponent === 'control_panel') {
      const dialogRef1 = this.dialog.open(ComponentFlavoursComponent, {
        width: '450px',
      });
    }
    if (this.hostComponent === 'control_panele') {
      const dialogRef1 = this.dialog.open(ComponentFlavoursComponent, {
        // position :{
        //     top: '',
        //     right: '0'
        // },
        width: '450px',

        panelClass: 'my-dialog',
        // height:'500px',

        data: { node },
      });
    }
  }
}
