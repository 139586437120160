// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  region: 'us-east-1',
  identityPoolId: 'us-east-1:34b75940-e9f3-4edc-9df7-3ac8a39f6a54',
  unAuthRoleARN:
    'arn:aws:iam::507831496546:role/kloudjet-infra-identity-de-CognitoUnAuthorizedRole-1U72WGEH59UW8',
  authRoleARN:
    'arn:aws:iam::507831496546:role/kloudjet-infra-identity-dev-CognitoAuthorizedRole-SUIXQ5W7P2Z',
  userPoolId: 'us-east-1_LmZBectnO',
  clientId: '3od2i3dabtfo143kro6psui58j',
  getIdPoolLogins(userRole?: string) {
    switch (userRole) {
      default:
        return (
          'cognito-idp.' + this.region + '.amazonaws.com/' + this.userPoolId
        );
    }
  },
  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  ASSETS_URL: 'https://assets.ascendmedia.online',
  TWELVE_HOURS_MILLI: 4.32e7,
  API_HOST: '.execute-api.us-east-1.amazonaws.com',
  SAVE_PROJ:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/project/saveEditProject',
  GET_PROJ_COMP:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/project/get',
  CREATE_PROJECT:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/project/create',
  KLOUDJET_STORAGE_BUCKET: 'kloudjet-admin-portal-storage-dev',
  GET_TEMPLATE_EDITOR:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/getTemplateEditorPortal',
  // for local host run admin on 4200
  // DASHBOARD_URL: 'http://localhost:4200/',
  // COOKIE_DOMAIN: '',
  // for development mode
  DASHBOARD_URL: 'https://dev.kloudjet.com/',
  COOKIE_DOMAIN: '.kloudjet.com',
  GENERATE_SIGNED_S3_URL:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template/generateSignedS3Url',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
