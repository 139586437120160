<ngx-graph
  class="chart-container"
  [view]="[1400, 570]"
  [zoomToFit$]="zoomToFit$"
  [center$]="center$"
  [links]="links"
  [nodes]="nodes"
  [layout]="layout"
  [curve]="curve"
  [update$]="update$"
  (select)="onSelect($event)"
  (zoomChange)="onZoomChange($event)"
>
  <ng-template #defsTemplate>
    <svg:marker
      id="arrow"
      viewBox="0 -5 10 10"
      refX="8"
      refY="0"
      markerWidth="4"
      markerHeight="4"
      orient="auto"
    >
      <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
    </svg:marker>
  </ng-template>

  <ng-template #nodeTemplate let-node>
    <svg viewBox="0 0 100 100" height="70px" width="70px">
      <svg:g
        [node]="node"
        [hostComponent]="'canvas'"
        on-mouseover="(!isHovered)"
        on-mouseout="isHovered = !isHovered"
        viewBox="0 0 100 100"
      ></svg:g>
    </svg>
  </ng-template>
  <ng-template #linkTemplate let-link>
    <svg:g class="edge">
      <svg:path
        class="line"
        stroke-width="2"
        marker-end="url(#arrow)"
      ></svg:path>
      <svg:text class="edge-label" text-anchor="middle">
        <textPath
          class="text-path"
          [attr.href]="'#' + link.id"
          [style.dominant-baseline]="link.dominantBaseline"
          startOffset="50%"
        >
          {{ link.label }}
        </textPath>
      </svg:text>
    </svg:g>
  </ng-template>
</ngx-graph>
