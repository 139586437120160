<div
  id="nav-container"
  [ngClass]="{ 'sidenav-toggled': isCollapsed, sidenav: true }"
>
  <div class="row-2 first-row-height remove-padding">
    <div class="col-sm-11 remove-padding icon-div-width">
      <div
        [ngClass]="{
          'icon-container': !isCollapsed,
          'expanded-icon-container': isCollapsed
        }"
      >
        <div
          class="collapse-icon-container"
          (click)="toggleSideNav()"
          data-toggle="collapse"
          data-target="#nav-container"
        >
          <img
            *ngIf="!isCollapsed"
            src="../../assets/icons/collapse-arrow.svg"
            class="collapse-icon"
          />
          <img
            *ngIf="isCollapsed"
            src="../../assets/icons/expand-arrow.svg"
            class="collapse-icon"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row-10 second-row-height remove-padding">
    <div class="col-sm-11 remove-padding sidenav-div">
      <div class="sidenav-bg-color">
        <!-- child component for editor side nav contents can be put here -->
        <app-control-panel
          [isCollapsed]="isCollapsed"
          (sendCollapsedStatus)="expandSearchBar($event)"
        >
        </app-control-panel>
      </div>
    </div>
  </div>
</div>
