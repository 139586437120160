import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NodeService } from 'src/app/services/node.service';
import {
  ISignedUrlBodyParams,
  UploadServiceService,
} from '../../services/upload-service.service';
import { AppComponent } from 'src/app/app.component';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';
import { NotifierToastService } from 'src/app/shared/shared-services/notifier-toast.service';
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit {
  dataimage: any;
  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose File';
  uploading = false;
  projectDetail = new FormGroup({
    // name: new FormControl(''),
    architectureDiagram: new FormControl(''),
    deploymentDiagram: new FormControl(''),
    // logo: new FormControl(''),
    // platform: new FormControl(''),
    // technology: new FormControl(''),
  });
  formObj: any = {};
  folderName = 'diagram/';

  constructor(
    private dialog: MatDialog,
    private upload: UploadServiceService,
    private nodeService: NodeService,
    private app: AppComponent,
    private cookieService: CookieService,
    private notifierToast: NotifierToastService
  ) {}

  ngOnInit(): void {
    // if (this.cookieService.get('tempTemplate')) {
    //   let obj = JSON.parse(this.cookieService.get('tempTemplate'));
    //   if (obj['deploymentDiagram'])
    //     this.projectDetail.controls['deploymentDiagram'].setValue(
    //       obj['deploymentDiagram']
    //     );
    //   if (obj['architectureDiagram'])
    //     this.projectDetail.controls['architectureDiagram'].setValue(
    //       obj['architectureDiagram']
    //     );
    // }
  }

  uploadFiles(fileInputEvent: any, key: string, fileSize: string): void {
    const uploadedFile: File = fileInputEvent.target.files[0];
    if (uploadedFile) {
      this.uploading = true;
      let name = uploadedFile.name.replace(/ /g, '-');
      const lastIndex = name.lastIndexOf('.');
      const signedUrlBodyParams: ISignedUrlBodyParams = {
        filePath: this.folderName + name.slice(0, lastIndex)+'_'+Math.floor(100000 + Math.random() * 900000)+'_'+Date.now()+name.slice(lastIndex),
        fileType: uploadedFile.type,
      };
      // console.log(this.folderName + name.slice(0, lastIndex)+'_'+Math.floor(100000 + Math.random() * 900000)+'_'+Date.now()+name.slice(lastIndex))
      this.upload.generateSignedUrl(signedUrlBodyParams).subscribe(
        (generatedUrl) => {
          this.upload
            .uploadFileToS3(generatedUrl, uploadedFile, uploadedFile.type)
            .subscribe(
              () => {
                const uploadedFileUrl = generatedUrl.split('?')[0];
                this.projectDetail.controls[key].setValue(uploadedFileUrl);
                console.log(uploadedFileUrl, key);
                this.nodeService.template[key] = uploadedFileUrl;
                this.uploading = false;
                this.cookieService.set(
                  'tempTemplate',
                  JSON.stringify(this.nodeService.template),
                  {
                    domain: environment.COOKIE_DOMAIN,
                  }
                );
              },
              (err) => {
                this.uploading = false;
                console.log('upload err', err);
              }
            );
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  async saveProject(): Promise<any> {
    this.uploading = true;
    await this.nodeService
      .saveProjectTemplate(
        JSON.parse(this.cookieService.get('projectDetails')).name,
        JSON.parse(this.cookieService.get('loggedInUser')).id,
        JSON.stringify(
          JSON.parse(this.cookieService.get('projectDetails')).env_array
        )
      )
      .subscribe(
        (data: any) => {
          this.cookieService.set('projectId', data.id, {
            domain: environment.COOKIE_DOMAIN,
          });
          this.cookieService.delete('projectDetails','/',environment.COOKIE_DOMAIN
          );
          this.cookieService.delete('templateId','/',environment.COOKIE_DOMAIN
          );
          this.cookieService.delete('tempTemplate','/',environment.COOKIE_DOMAIN
          );
          window.location.href =environment.DASHBOARD_URL + `/projects/create/artifacts`;
          this.uploading = false;
        },
        (err: any) => {
          this.notifierToast.showNotification(
            'error',
            'Please Try Saving Again'
          );
        }
      );
  }
}
