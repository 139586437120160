import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inode } from 'src/app/interfaces/node.model';
import { NodeService } from 'src/app/services/node.service';
import { map, map as nodesArray } from 'src/app/constants/node-map';
import { NodeComponent } from '../node/node.component';
import { Node } from '@swimlane/ngx-graph/lib/models';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-component-details',
  templateUrl: './component-details.component.html',
  styleUrls: ['./component-details.component.scss'],
})
export class ComponentDetailsComponent implements OnInit {
  public component: Inode;
  public ingress: Inode[] = [];
  public egress: Inode[] = [];
  public nodeval: any = [];
  graphNode: string[] = [];
  update$: Subject<boolean> = new Subject();
  inboundBatch: Inode[] = [];
  outboundBatch: Inode[] = [];
  constructor(
    public dialogRef: MatDialogRef<ComponentDetailsComponent>,
    public translate: TranslateService,

    @Inject(MAT_DIALOG_DATA) private data: any,
    private nodeService: NodeService
  ) {
    this.component = data.node;
    this.getrules();
  }
  ngOnInit(): void {}

  multiSelectInbound(batch: Inode): void {
    if (this.inboundBatch.indexOf(batch) === -1) {
      this.inboundBatch.push(batch);
    } else {
      const _index = this.inboundBatch.indexOf(batch);
      this.inboundBatch.splice(_index, 1);
    }
  }
  multiSelectOutbound(batch: Inode): void {
    if (this.outboundBatch.indexOf(batch) === -1) {
      this.outboundBatch.push(batch);
    } else {
      const _index = this.outboundBatch.indexOf(batch);
      this.outboundBatch.splice(_index, 1);
    }
  }
  getrules(): void {
    let links: any;
    this.graphNode = this.nodeService.getgraphNodes();
    this.nodeService.selectedEdgeList$.subscribe((data) => {
      links = data;
    });
    if (this.component.meta && this.component.meta.ingress) {
      this.component.meta?.ingress?.forEach((element) => {
        let add = true;
        links.forEach((link: any) => {
          if (link.source === element && link.target === this.component.id) {
            add = false;
          }
        });
        nodesArray.forEach((x) => {
          if (element === x.id && add) {
            this.ingress.push(x);
          }
        });
      });
    }

    if (this.component.meta && this.component.meta.egress) {
      this.component.meta?.egress?.forEach((element) => {
        let add = true;
        links.forEach((link: any) => {
          if (link.target === element && link.source === this.component.id) {
            add = false;
          }
        });
        nodesArray.forEach((x) => {
          if (element === x.id && add) {
            this.egress.push(x);
          }
        });
      });
    }
  }

  addNodes(component: Inode): void {
    this.inboundBatch.forEach((element) => {
      element.id = element.id;
      this.nodeService.cloneNode(element);
      this.nodeService.drawEdge(element.id, component.id);
    });
    this.outboundBatch.forEach((element) => {
      element.id = element.id;
      this.nodeService.cloneNode(element);
      this.nodeService.drawEdge(component.id, element.id);
    });
    this.dialogRef.close();
  }

  deleteNode(component: Inode): void {
    this.nodeService.deleteNode();
  }
}
