import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardServiceGuard implements CanActivate {
  constructor(private router: Router, private cookieService: CookieService) {}
  canActivate(): boolean {
    // if (!(this.cookieService.get('loggedInUser')||this.cookieService.get('projectId'))) {
    //   console.log("Auth guard",this.cookieService.getAll())
    //   window.location.href = environment.DASHBOARD_URL;
    // }

    return true;
  }
}
