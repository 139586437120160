import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { NodeService } from 'src/app/services/node.service';
import { map as nodesArray } from 'src/app/constants/node-map';
import { Inode } from 'src/app/interfaces/node.model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { category } from 'src/app/constants/category';
import { ICollapseStatus } from '../../side-nav-container/side-nav-container.utils';
@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss'],
})
export class ControlPanelComponent implements OnInit, AfterViewInit {
  public largeIcon = false;
  @ViewChild('searchbar')
  searchbar!: ElementRef;
  loggedInUser: any = {};
  searchText = '';
  public nodes: Inode[];
  nodeval: any = [];
  availableCategories: any = [];
  // ['aws','aws-extended components', 'gcp','gcp-extended components', 'azure','azure-extended components', 'other'];
  @Output() selectednode = new EventEmitter<boolean>();
  categorisedNodes: any = {};
  @Input() isCollapsed = false;
  @Output() sendCollapsedStatus: EventEmitter<ICollapseStatus> =
    new EventEmitter();

  constructor(
    private nodeService: NodeService,
    private localStorageService: LocalStorageService
  ) {
    this.nodes = nodesArray;

    this.availableCategories = category;
    // this.loggedInUser=JSON.parse(this.localStorageService.getItem('loggedInUser'));
    this.loggedInUser = this.localStorageService.getItem('loggedInUser');
  }

  openSearch(): void {
    this.searchbar.nativeElement.focus();
  }
  searchClose(): void {
    this.searchText = '';
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  showNodesCategoryWise(selectedCategory: string): void {
    const filteredNodes = this.nodes.filter(
      (node) =>
        (node.platform && node.platform.toUpperCase()) ===
        selectedCategory.toUpperCase()
    );
    this.categorisedNodes[selectedCategory] = filteredNodes;
  }

  expandSearchBar(): void {
    this.sendCollapsedStatus.emit({ isCollapsed: false });
  }
}
