import { Component, OnInit, Input, Injectable } from '@angular/core';
import { NodeService } from '../../../services/node.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from '../../../modules/confirmation-dialog/confirmation-dialog.component';
import { ProjectDetailsComponent } from '../../../modules/project-details/project-details.component';
import { CookieService } from 'ngx-cookie-service';
import { NotifierToastService } from '../../shared-services/notifier-toast.service';
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() templateName: any;
  isSaved = false;
  // isMap:any;
  scratch = false;
  constructor(
    private nodeService: NodeService,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private notifierToast: NotifierToastService
  ) {}

  ngOnInit(): void {
    // console.log(this.nodeService.componentmap)
    // this.isMap=this.nodeService.componentmap
  }
  async saveproject(): Promise<any> {    

  const dialogRef = this.dialog.open(ProjectDetailsComponent, {
    width: '490px',
    data: {},
  });
 
    
  }

  clickMethod(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
    });
  }
  savedetails() {
    const dialogRef = this.dialog.open(ProjectDetailsComponent, {
      width: '490px',
      data: {},
    });
  }
}
