import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuardServiceGuard } from './services/auth-guard-service.guard';

const routes: Routes = [
  { path: '', component: AppComponent, canActivate: [AuthGuardServiceGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
