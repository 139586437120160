import {
  Component,
  Injectable,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { map } from './constants/node-map';
import { DiagComponent } from './modules/diag/diag.component';
import { NodeService } from './services/node.service';
import { category } from './constants/category';
import { CookieService } from 'ngx-cookie-service';
import { HeaderComponent } from './shared/shared-component/header/header.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(DiagComponent)
  child!: DiagComponent;
  centreEventCalled = false;
  is_loaded_download = false;
  constructor(
    private nodeService: NodeService,
    public cookieService: CookieService,
    private header: HeaderComponent,
    public translate: TranslateService
  ) {
    translate.setDefaultLang('en');
  }

  templateName: any;
  selectednode: any;
  selectedID: any;
  public fixed = true;
  public top = 0;
  public bottom = 0;
  sidenavWidth = 50;
  ngOnInit(): void {
    this.templateName = JSON.parse(
      this.cookieService.get('projectDetails')
    ).name;
    const temp = this.nodeService.getdetails().subscribe((data: any) => {
      data.componentRule.forEach((element: any) => {
        map.push(element);
        if (!category.includes(element.platform.toUpperCase())) {
          category.push(element.platform.toUpperCase());
        }
      });
      category.sort();
      if (data.template) {
        this.nodeService.inittemplate(data.template);
        if (
          this.cookieService.get('tempTemplate') &&
          JSON.parse(this.cookieService.get('tempTemplate')) != data.template
        ) {
          data.template = JSON.parse(this.cookieService.get('tempTemplate'));
          this.nodeService.inittemplate(
            JSON.parse(this.cookieService.get('tempTemplate'))
          );
        }
        this.header.ngOnInit();
        this.nodeService.initnodes();
        this.nodeService.initlinks();
      } else {
        if (this.cookieService.get('tempTemplate')) {
          this.nodeService.inittemplate(
            JSON.parse(this.cookieService.get('tempTemplate'))
          );
          data.template = JSON.parse(this.cookieService.get('tempTemplate'));
          this.header.ngOnInit();
          this.nodeService.template['label']='Created From Scratch'
          console.log(this.nodeService.componentmap)
          this.nodeService.initnodes();
          this.nodeService.initlinks();
        }
      }
    });
  }
  select(selectednode: any): void {
    this.selectednode = selectednode;
  }
  selectid(selectedid: any): void {
    this.selectedID = selectedid;
  }

  centergraph(): void {
    this.centreEventCalled = true;
    // this.child.centerGraph();
  }

  // saveFlowChart(){
  //     let downloadLink = document.createElement('a');
  //     downloadLink.setAttribute('download', 'CanvasAsImage.png');
  //      let node = document.getElementById('outerdiv')
  //      if(node){
  //      html2canvas(node).then(async function (canvas){
  //     let dataURL = canvas.toDataURL('image/png');
  //     let url = dataURL.replace(/^data:image\/png/,'data:application/octet-stream');
  //     downloadLink.setAttribute('href',url);
  //     downloadLink.click();
  //      })
  //     }
  // }

  ngOnDestroy() {
    this.cookieService.delete('projectId', '/', environment.COOKIE_DOMAIN);
    this.cookieService.delete('loggedInUser', '/', environment.COOKIE_DOMAIN);
    this.cookieService.delete('tempTemplate', '/', environment.COOKIE_DOMAIN);
  }
}
