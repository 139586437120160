import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(private cookieService: CookieService) {}

  ngOnInit(): void {}
  gotoDashboard(): void {
    this.cookieService.delete('projectDetails', '/', environment.COOKIE_DOMAIN);
    this.cookieService.delete('templateId', '/', environment.COOKIE_DOMAIN);
    this.cookieService.delete('tempTemplate', '/', environment.COOKIE_DOMAIN);
    window.location.href = environment.DASHBOARD_URL + '/projects/create';
  }
  reload() {
    // window.location.reload();
  }
}
