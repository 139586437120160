<div>
  <div class="actions">
    <form [formGroup]="projectDetail">
      <div class="row form-start">
        <!-- <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ "common.project_name" | translate }}: </mat-label>
            <input
              matInput
              placeholder="Project Name"
              required
              formControlName="name"
            />
          </mat-form-field>
        </div> -->
        <div class="col-6">
          <div class="row">
            <mat-label class="file">{{
              "common.add_architecture_diagram" | translate
            }}</mat-label>
          </div>
          <div class="row">
            <input
              (change)="uploadFiles($event, 'architectureDiagram', '2000000')"
              type="file"
              class="file-input"
              id="architectureDiagram"
            />
          </div>
        </div>
        <!-- </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ "common.technology" | translate }}: </mat-label>
            <input
              matInput
              placeholder="JAVA, Angular, etc."
              required
              formControlName="technology"
            />
          </mat-form-field>
        </div> -->
        <div class="col-6">
          <div class="row">
            <mat-label class="file">{{
              "common.add_deployment_diagram" | translate
            }}</mat-label>
          </div>
          <div class="row">
            <input
              (change)="uploadFiles($event, 'deploymentDiagram', '2000000')"
              type="file"
              class="file-input"
              id="deploymentDiagram"
            />
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ "common.platform" | translate }}: </mat-label>
            <input
              matInput
              placeholder="AWS, Azure, GCP, etc."
              required
              formControlName="platform"
            />
          </mat-form-field>
        </div>
        <div class="col-6">
          <div class="row">
            <mat-label class="file">{{
              "common.add_logo" | translate
            }}</mat-label>
          </div>
          <div class="row">
            <input
              (change)="uploadFiles($event, 'logo','200000')"
              type="file"
              class="file-input"
              id="logo"
            />
          </div>
        </div>
      </div> -->
      <div class="space"></div>
    </form>
    <button class="btn-cancel" [mat-dialog-close]="true">
      {{ "common.button.cancel" | translate }}
    </button>
    <button
      class="btn-add"
      *ngIf="!uploading"
      type="submit"
      (click)="saveProject()"
    >
      Save
    </button>
    <button
      class="btn-add"
      *ngIf="uploading"
      [class.spinner]="uploading"
      type="submit"
    >
      Save
    </button>
  </div>
</div>
