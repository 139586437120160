import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  CognitoUserPool,
  CognitoRefreshToken,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoUserSession,
  ICognitoUserSessionData,
  CognitoUser,
  ICognitoUserData,
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as awsservice from 'aws-sdk/lib/service';
import * as CognitoIdentity from 'aws-sdk/clients/cognitoidentity';
import { Observable, Observer } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
/**
 * Created by Om Gehlot
 */

export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;

  handleMFAStep?(
    challengeName: string,
    challengeParameters: ChallengeParameters,
    callback: (confirmationCode: string) => any
  ): void;
}

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;

  CODE_DELIVERY_DESTINATION: string;
}

export interface Callback {
  callback(): void;

  callbackWithParam(result: any): void;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoUtil {
  public static _REGION = environment.region;

  public static _IDENTITY_POOL_ID = environment.identityPoolId;
  public static _USER_POOL_ID = environment.userPoolId;
  public static _CLIENT_ID = environment.clientId;

  public static _POOL_DATA: any = {
    UserPoolId: CognitoUtil._USER_POOL_ID,
    ClientId: CognitoUtil._CLIENT_ID,
  };

  public cognitoCreds: AWS.CognitoIdentityCredentials;

  constructor(public cookieService: CookieService) {}
  getUserPool() {
    if (environment.cognito_idp_endpoint) {
      CognitoUtil._POOL_DATA.endpoint = environment.cognito_idp_endpoint;
    }
    return new CognitoUserPool(CognitoUtil._POOL_DATA);
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  // AWS Stores Credentials in many ways, and with TypeScript this means that
  // getting the base credentials we authenticated with from the AWS globals gets really murky,
  // having to get around both class extension and unions. Therefore, we're going to give
  // developers direct access to the raw, unadulterated CognitoIdentityCredentials
  // object at all times.
  setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
    this.cognitoCreds = creds;
  }

  getCognitoCreds() {
    return this.cognitoCreds;
  }

  // This method takes in a raw jwtToken and uses the global AWS config options to build a
  // CognitoIdentityCredentials object and store it for us. It also returns the object to the caller
  // to avoid unnecessary calls to setCognitoCreds.

  buildCognitoCreds(idTokenJwt: string) {
    let url =
      'cognito-idp.' +
      CognitoUtil._REGION.toLowerCase() +
      '.amazonaws.com/' +
      CognitoUtil._USER_POOL_ID;
    if (environment.cognito_idp_endpoint) {
      url = environment.cognito_idp_endpoint + '/' + CognitoUtil._USER_POOL_ID;
    }
    let logins: CognitoIdentity.LoginsMap = {};
    logins[url] = idTokenJwt;
    let params = {
      IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID /* required */,
      Logins: logins,
    };
    let serviceConfigs = <awsservice.ServiceConfigurationOptions>{};
    if (environment.cognito_identity_endpoint) {
      serviceConfigs.endpoint = environment.cognito_identity_endpoint;
    }
    let creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
    this.setCognitoCreds(creds);
    return creds;
  }

  getCognitoIdentity(): string {
    return this.cognitoCreds.identityId;
  }

  getAccessToken(callback: Callback): void {
    if (callback == null) {
      throw 'CognitoUtil: callback in getAccessToken is null...returning';
    }
    if (this.getCurrentUser() != null) {
      // @ts-ignore: Object is possibly 'null'
      this.getCurrentUser().getSession(function (err: any, session: any) {
        if (err) {
          // console.log(`CognitoUtil: Cant set the credentials:` + err);
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getAccessToken().getJwtToken());
          }
        }
      });
    } else {
      callback.callbackWithParam(null);
    }
  }

  getIdToken(callback: Callback): void {
    if (callback == null) {
      throw 'CognitoUtil: callback in getIdToken is null...returning';
    }
    if (this.getCurrentUser() != null)
      // @ts-ignore: Object is possibly 'null'
      this.getCurrentUser().getSession(function (err: any, session: any) {
        if (err) {
          // console.log('CognitoUtil: Cant set the credentials:' + err);
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getIdToken().getJwtToken());
          } else {
            // console.log(`CognitoUtil: Got the id token, but the session isn't valid`);
          }
        }
      });
    else callback.callbackWithParam(null);
  }

  getRefreshToken(callback: Callback): void {
    if (callback == null) {
      throw 'CognitoUtil: callback in getRefreshToken is null...returning';
    }
    if (this.getCurrentUser() != null)
      // @ts-ignore: Object is possibly 'null'
      this.getCurrentUser().getSession(function (
        err: any,
        session: { isValid: () => any; getRefreshToken: () => any }
      ) {
        if (err) {
          // console.log('CognitoUtil: Cant set the credentials:' + err);
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getRefreshToken());
          }
        }
      });
    else callback.callbackWithParam(null);
  }

  refresh(): void {
    // @ts-ignore: Object is possibly 'null'
    this.getCurrentUser().getSession(function (
      err: any,
      session: { isValid: () => any }
    ) {
      if (err) {
        // console.log('CognitoUtil: Cant set the credentials:' + err);
      } else {
        if (session.isValid()) {
          // console.log('CognitoUtil: refreshed successfully');
        } else {
          // console.log('CognitoUtil: refreshed but session is still not valid');
        }
      }
    });
  }

  async genrateNewToken() {
    localStorage.clear();
    localStorage.setItem('loggedInUser', this.cookieService.get('userDetails'));
    let user = JSON.parse(this.cookieService.get('userDetails')!);
    //@ts-ignore
    let userEmail = user.email;
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${environment.clientId}.LastAuthUser`,
      userEmail
    );
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.idToken`,
      this.cookieService.get('idToken')
    );
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.refreshToken`,
      this.cookieService.get('refreshToken')
    );
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.accessToken`,
      this.cookieService.get('accessToken')
    );
    const that = this;
    // @ts-ignore: Object is possibly 'null'
    this.getCurrentUser().getSession(async function (err: any, session: any) {
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: environment.identityPoolId,
        Logins: {
          '`cognito-idp.${enviornment.region}.amazonaws.com/${environment.userPoolId}`':
            session.getIdToken().getJwtToken(),
        },
      });
      var refreshToken = session.getRefreshToken().getToken();
      var token = new CognitoRefreshToken({ RefreshToken: refreshToken });
      let userData = {
        Username: that.cookieService.get('userDetails'),
        Pool: new CognitoUserPool(CognitoUtil._POOL_DATA),
      };
      console.log(that.cookieService.get('userDetails'));
      let cognitoUser = new CognitoUser(userData);
      await cognitoUser.refreshSession(token, async function (err, session) {
        var idToken = session.getIdToken().getJwtToken();
        var uname = session.getIdToken().payload['cognito:username'];
        var accessToken = session.getAccessToken().getJwtToken();
        var refreshToken = session.getRefreshToken().getToken();
        let observer = new Observable((observer: Observer<any>) => {});
        AWS.config.credentials = that.buildCognitoCreds(idToken);
        that.cookieService.set('idToken', idToken, {
          domain: environment.COOKIE_DOMAIN,
        });
        that.cookieService.set('accessToken', accessToken, {
          domain: environment.COOKIE_DOMAIN,
        });
        that.cookieService.set('refreshToken', refreshToken, {
          domain: environment.COOKIE_DOMAIN,
        });
        window.location.reload();
      });
    });
  }
}
