<div class="header">
  <div class="logo pointer">
    <img src="../../../assets/icons/logo.svg" (click)="clickMethod()" />
  </div>
  <img
    class="icon pointer"
    src="../../../assets/icons/back.svg"
    (click)="clickMethod()"
  />
  <span
    class="plain-text semi-bold-font"
    fxShow.sm="false"
    fxShow.gt-md="false"
    fxShow="true"
    >{{ templateName | slice: 0:80 }}</span
  >
  <span
    class="plain-text"
    fxShow.sm="false"
    fxShow.gt-md="false"
    fxShow="true"
    *ngIf="templateName?.length > 80"
    [matTooltip]="templateName"
    >...</span
  >
  <!-- <img
    class="icon disabled"
    src="../../../assets/icons/edit-project-name-disabled.svg"
  /> -->
  <span class="save-spacer"></span>
  <button mat-button class="outline" [disabled]="true">
    {{ "common.button.take_tour" | translate }}
  </button>
  <div class="saveBtn">
    <button
      mat-raised-button
      class="regular-font button pointer button-height"
      [class.spinner]="isSaved"
      [disabled]="isSaved"
      (click)="saveproject()"
    >
      Save
    </button>
  </div>
</div>

<!-- <div class="header" *ngIf="scratch">
  <div class="logo pointer">
    <img src="../../../assets/icons/logo.svg" (click)="clickMethod()" />
  </div>
  <img
    class="icon pointer"
    src="../../../assets/icons/back.svg"
    (click)="clickMethod()"
  />
  <span class="save-spacer"></span>
  <button mat-button class="outline" [disabled]="true">
    {{ "common.button.take_tour" | translate }}
  </button>
  <div class="saveBtn">
    <button
      mat-raised-button
      class="regular-font button pointer button-height"
      (click)="savedetails()"
    >
      {{ "common.enter_details" | translate }}
    </button>
  </div>
</div> -->
